import { render, staticRenderFns } from "./CourseCardsOnline.vue?vue&type=template&id=f6792782&"
import script from "./CourseCardsOnline.vue?vue&type=script&lang=js&"
export * from "./CourseCardsOnline.vue?vue&type=script&lang=js&"
import style0 from "./CourseCardsOnline.vue?vue&type=style&index=0&id=f6792782&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsCourseCard: require('/opt/build/repo/components/Cards/CourseCard.vue').default,CardsMoreInfoModal: require('/opt/build/repo/components/Cards/MoreInfoModal.vue').default})
