
import SsrCarousel from 'vue-ssr-carousel'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "CourseCardsOnline",
    components: { SsrCarousel },
    props: {
        region: { default: 'uk', type: String },
        count: { default: 2, type: Number },
        content: { default: true, type: Boolean }
    },
    computed: {
        ...mapGetters({
            activeModal: 'pages/getActiveModal'
        }),
    },
    methods: {
        ...mapActions({
            updateActiveModal: 'pages/updateActiveModal'
        }),
        updateModal(id) {
            this.updateActiveModal(id)
        },
        hideModal() {
            this.updateActiveModal(null)
        }
    }
}
