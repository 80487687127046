
export default {
    name: "CourseCard",
    props: {
        productId: { type: String, required: true },
        image: { type: String, required: true },
        alt: { type: String, required: true },
        title: { type: String, required: true },
        subtitle: { type: String, default: 'Learn Online' },
        description: { type: String, required: true },
        badge: { type: String, default: '' },
        dataCy: { type: String, required: true },
        modalId: { type: String, required: true },
        background: { type: String, default: 'bggreen' },
        region: { type: String, default: 'uk' },
    },
    computed: {
        formattedTitle() {
            // Match something like "120-hour", "230-hour", etc. at the start
            const match = this.title.match(/^(\d{2,3}-hour)(.*)/i);
            if (match) {
            const [, hours, rest] = match;
            return `<span class="yellow">${hours}</span>${rest}`;
            }
            return this.title; // fallback if no match
        },
        formattedSubtitle() {
            return this.subtitle.replace(/\|/g, '<span class="yellow">|</span>');
        }
    },
    methods: {
        updateModal(id) {
            this.$store.dispatch('pages/updateActiveModal', id);
        }
    }
}
